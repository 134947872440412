import React from 'react';

import logo from '../../assets/logo.png';

import { Container } from './styles'

const Home: React.FC = () => {
  return (
    <Container>
      <img src={logo} alt="logo" />
    </Container>
  )
}

export default Home;